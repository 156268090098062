import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";

import Layout from "../../components/layout/layout";
import Button from "../../components/button/button";
import Faq from "../../components/faq/faq";
import "./style.scss";

import { submit } from "../../utils/submit";
import SEO from "../../components/seo";
import NumberFormat from "react-number-format";

import ReCAPTCHA from "react-google-recaptcha";
import InputTelephone from "../../shared/ui/inputTelephone";
import InputSirenSiret from "../../shared/ui/inputSirenSiret";
import InputMail from "../../shared/ui/inputMail";
import FormError from "../../components/formError";
import ChampsObligatoiresMention from "../../components/champsObligatoiresMention";

const AgenceIndisponible = ({location}) => {
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [infoForm, setInfoForm] = useState({"code":"agence-indisponible"});
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInfoForm((prevInfo) => ({
      ...prevInfo,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleNumberFormattedInput = (values, sourceInfo) => {
    setInfoForm((prevInfo) => ({
      ...prevInfo,
      [sourceInfo.event.target.name]: values?.value?.trim(),
    }));
  }

  const handleError = () => {
    setFormError(true);
    document.getElementById("form-error")?.scrollIntoView();
  }

  const isInfoEntrepriseObligatoire = () => {
    return !!infoForm["raison-sociale"];
  }

  const handleInputEntreprisePlaceholderFormat = (text) => {
    return `${text}${isInfoEntrepriseObligatoire() ? ' *' : ''}`
  }

  useEffect(() => {
    if(location?.state?.codePostal) {
      setInfoForm((prevInfo) => ({
        ...prevInfo,
        ["code-postal"]: location.state.codePostal,
      }));
    }
  }, []);

  return (
    <>
      <Helmet htmlAttributes={{
        class: "no-scrolling"
      }}>
        <body />
      </Helmet>
      <Layout>
        <SEO title="Agence indisponible" />
        <section className="mon-agence-indisponible">
          <h2>Nous n'avons pas d'agence <strong>dans votre département&nbsp;!</strong></h2>
          <div className="sub_title">
            <p>
              Vous pouvez toutefois bénéficier des offres de L’Auxiliaire par l’intermédiaire de l’un de nos <strong>courtiers partenaires</strong> expert dans le secteur du bâtiment et des travaux publics.
            </p>
            <br></br>
            <p>
              Nous vous invitons à remplir le formulaire ci-dessous. Un conseiller donnera suite à votre demande dans les plus brefs délais. 
            </p>
          </div>
          <div className="formNotFoundContainer">
          <form
            action=""
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              if(!isLoading && isCaptchaValid) {
                setIsLoading(true);
                submit(infoForm).then((response) => {
                  if(response?.ok) {
                    navigate("/merci-pour-votre-demande");
                  } else {
                    handleError();
                  }
                  setIsLoading(false);
                }, () => {
                  handleError();
                  setIsLoading(false);
                });
              }
            }}
          >
            {formError && <FormError />}
            <h2>
              <strong>Indiquez-nous</strong><br/> votre...
            </h2>
            <br></br>
            <div className="form__radio">
              <p className="form__radio-question">Votre civilité *</p>
              <div className="form__radio-container">
                <div>
                  <input
                    type="radio"
                    name="civilite"
                    id="madame"
                    value="Madame"
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="madame">Madame</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="civilite"
                    id="monsieur"
                    value="Monsieur"
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="monsieur">Monsieur</label>
                </div>
              </div>
            </div>
            <div>
              <p>votre</p>
              <input
                type="text"
                name="prenom"
                id="prenom"
                placeholder="prénom *"
                onChange={handleChange}
                required
              />
              ,
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="nom *"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="email">votre</label>
              <InputMail
                  inputName="email"
                  inputId="email"
                  inputPlaceholder="mail *"
                  inputRequired={true}
                  inputHandleChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="tel">votre</label>
              <InputTelephone
                inputName="tel"
                inputId="tel"
                inputPlaceholder="numéro de téléphone *"
                inputHandleChange={handleChange}
                inputRequired={true}
              />
            </div>
            <div>
              <label htmlFor="adresse">votre</label>
              <input
                type="text"
                name="adresse"
                id="adresse"
                placeholder="adresse *"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <p>ainsi que votre</p>
              <input
                type="number"
                name="code-postal"
                id="code-postal"
                max={99999}
                onInput={(e) => e.target.value = e.target.value.slice(0, 5)}
                placeholder="code postal *"
                onChange={handleChange}
                required
                defaultValue={infoForm["code-postal"]}
              />
              ,
              <input
                type="text"
                name="ville"
                id="ville"
                placeholder="ville *"
                onChange={handleChange}
                required
              />
            </div>
            <br></br>
            <Faq question="Vous êtes professionnel&nbsp;?" number={1} >
              <h2 className="mon-agence-indisponible__professionnel-title">
                <span>Concernant </span><br/><strong>votre entreprise</strong>
              </h2>
              <div className="formDiv">
                <label htmlFor="raison-sociale">Sa</label>
                <input
                  type="text"
                  name="raison-sociale"
                  placeholder="raison sociale"
                  onChange={handleChange}
                />
                <span className="form__comma">,</span>
                <label htmlFor="siren">son</label>
                <InputSirenSiret
                  inputName="siren"
                  inputPlaceholder={handleInputEntreprisePlaceholderFormat("SIREN")}
                  inputHandleChange={handleChange}
                  inputRequired={isInfoEntrepriseObligatoire()}
                />
              </div>
              <div className="formDiv">
                <label htmlFor="raison-sociale">Son</label>
                <input
                  type="number"
                  name="effectif"
                  placeholder={handleInputEntreprisePlaceholderFormat("effectif")}
                  onChange={handleChange}
                  max={100000}
                  required={isInfoEntrepriseObligatoire()}
                />
                <span className="form__comma">,</span>
                <NumberFormat
                    id="chiffre-affaires"
                    name="chiffre-affaires"
                    placeholder={handleInputEntreprisePlaceholderFormat("chiffres d'affaires €")}
                    suffix=" €"
                    decimalScale={0}
                    thousandSeparator={" "}
                    allowNegative={false}
                    onValueChange={handleNumberFormattedInput}
                    required={isInfoEntrepriseObligatoire()}
                />
                <span className="form__comma">,</span>
                <input
                  type="text"
                  name="activite"
                  placeholder={handleInputEntreprisePlaceholderFormat("activité")}
                  onChange={handleChange}
                  required={isInfoEntrepriseObligatoire()}
                />
              </div>

              <div className="form__radio">
                <p className="form__radio-question">
                  Avez-vous le statut d’auto-entrepreneur&nbsp;?{isInfoEntrepriseObligatoire() && <>&nbsp;*</>}
                </p>
                <div className="form__radio-container">
                  <div>
                    <input
                      type="radio"
                      name="statut-auto-entrepreneur"
                      id="statut-yes"
                      value="oui"
                      onChange={handleChange}
                      required={isInfoEntrepriseObligatoire()}
                    />
                    <label htmlFor="statut-yes">Oui</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="statut-auto-entrepreneur"
                      id="statut-no"
                      value="non"
                      onChange={handleChange}
                      required={isInfoEntrepriseObligatoire()}
                    />
                    <label htmlFor="statut-no">Non</label>
                  </div>
                </div>
              </div>
              <div className="form__radio">
                <p className="form__radio-question">
                  Votre société est-elle en création&nbsp;?{isInfoEntrepriseObligatoire() && <>&nbsp;*</>}
                </p>
                <div className="form__radio-container">
                  <div>
                    <input
                      type="radio"
                      name="creation"
                      id="creation-yes"
                      value="oui"
                      onChange={handleChange}
                      required={isInfoEntrepriseObligatoire()}
                    />
                    <label htmlFor="creation-yes">Oui</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="creation"
                      id="creation-no"
                      value="non"
                      onChange={handleChange}
                      required={isInfoEntrepriseObligatoire()}
                    />
                    <label htmlFor="creation-no">Non</label>
                  </div>
                </div>
              </div>
              <div className="form__radio">
                <p className="form__radio-question">
                  Votre dernier contrat d’assurance a-t-il été résilié par votre
                  assureur&nbsp;?
                </p>
                <div className="form__radio-container">
                  <div>
                    <input
                      type="radio"
                      name="contrat-resilie"
                      id="contrat-resilie-yes"
                      value="oui"
                      onChange={handleChange}
                    />
                    <label htmlFor="contrat-resilie-yes">Oui</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="contrat-resilie"
                      id="contrat-resilie-no"
                      value="non"
                      onChange={handleChange}
                    />
                    <label htmlFor="contrat-resilie-no">Non</label>
                  </div>
                </div>
              </div>
            </Faq>
            <div className="courtage__checkbox">
              <input
                type="checkbox"
                name="contact"
                id="contact"
                onChange={handleChange}
                required
              />
              <label htmlFor="contact">
                J'accepte d'être contacté par L'Auxiliaire afin que l'on me
                propose des produits et services personnalisés.&nbsp;*
              </label>
            </div>
            <ChampsObligatoiresMention showFinaliteTraitementDonnees={true} />
            <ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_KEY}
              onChange={(val)=>{setIsCaptchaValid(!!val)}}
            />
            <Button
              className="form__btn"
              type="submit"
              text="Envoyer"
              isDisabled={!isCaptchaValid}
              isLoading={isLoading}
            />
          </form>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default AgenceIndisponible;
