import PropTypes from "prop-types";
import React from "react";

import "./faq.scss";

const Faq = ({ question, number, children, isChecked }) => (
  <div className="wrap-collabsible">
    <input
      id={`faq_${number}`}
      className="toggle"
      type="checkbox"
      onInput={() => {
        const list = document.querySelectorAll("input[type=checkbox][id^=faq_]");
        for(const input of list) {
            if(input?.id !== `faq_${number}`) {
                input.checked = false;
            }
        }
      }}
      defaultChecked={isChecked}
      readOnly={isChecked}
    />
    <label htmlFor={`faq_${number}`} className="lbl-toggle">
      {question}
    </label>
    <div className="collapsible-content">
      <div className="content-inner">{children}</div>
    </div>
  </div>
);

Faq.propTypes = {
  question: PropTypes.string,
  number: PropTypes.number,
};

Faq.defaultProps = {
  question: {},
  number: 0,
};

export default Faq;
